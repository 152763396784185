
 import PricingButton from "@/components/main/pricing/PricingButton";
 import RedHeadBox from "@/components/main/core/RedHeadBox";
 import PricingQuestion from "@/components/main/core/PricingQuestion";
 import TabsButtonsPricing from "@/components/main/tabs/TabsButtonsPricing";
 export default {
   name: "DatingTab",
   components: { PricingButton, RedHeadBox, PricingQuestion, TabsButtonsPricing },
   data() {
     return {
       current: 1,
     }
   },
    mounted() {
      this.$store.state.userModule.isLogged ? this.current = 2 : this.current = 1

    },

   methods: {
     change(id) {
       this.current = id;
     }
   }
 }
 