
export default {
  name: "TabsButtons",
  props: ['step', 'tab1', 'tab2', 'tab3', 'tab4', 'tabs', 'kick'],
  methods: {
    change(tab) {
      this.$emit('change', tab);
    }
  }
}
