
export default {
  name: "RedHeadBox",
  props: {
    width: {
      type: String,
      default: ''
    },
    mr: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    popular: {
      type: Boolean,
      default: false
    },
    bottomGrey: {
      type: Boolean,
      default: false
    },
    padding: {
      type: String,
      default: ''
    },
    isFixed: {
      type: Boolean,
      default: false
    },
  },

}
