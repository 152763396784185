
export default {
  name: "PricingQuestion",
  props: {
    text: {
      type: String,
      required: true,
    }
  },
  mixins: [],
  components: {},
  data() {
    return {
      isShow: false,
    }
  },
  computed: {},
  mounted() {
  },
  methods: {}
}
